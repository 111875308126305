import React, { useState, useEffect, useRef } from 'react';
import FilterComponent from './FilterComponent';
import UserPopup from './UserPopup';
import CategoryComponent from './CategoryComponent';
import ProductComponent from './ProductComponent';
import { Grid, Paper, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
// import nmaispeloImage from './n+p-logo.jpeg';
import nmaispeloImage from './logo1.jpeg';
import errorImage from './error.svg';
import CartComponent from './CartComponent';
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import PrintIcon from '@mui/icons-material/Print';
import html2canvas from 'html2canvas';

const POSComponent = ({ data, POS_PASSWORD, username, password, token }) => {
    const [selectedFilter, setSelectedFilter] = useState({ id: '', name: '' });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [orientation, setOrientation] = useState('');
    const [authorized, setAuthorized] = useState(false);
    const [openDialog, setOpenDialog] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [elevation, setElevation] = useState(3);

    useEffect(() => {
        function handleOrientationChange() {
            const isPortrait = window.innerHeight > window.innerWidth;
            setOrientation(isPortrait ? 'portrait' : 'landscape');
        }
        handleOrientationChange();
        window.addEventListener('resize', handleOrientationChange);
        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    useEffect(() => {
        setSelectedFilter({ id: '', name: '' });
        setCategories([]);
    }, []);

    const filters = data?.filters ?? [];

    const setCategoriesForSelectedFilter = (filterId) => {
        const selectedFilterObject = filters.find((filter) => filter.id === filterId);
        if (selectedFilterObject) {
            setCategories(selectedFilterObject.categories || []);
        } else {
            setCategories([]);
        }
    };

    const onSelectFilter = (filterId) => {
        const filterObject = filters.find((filter) => filter.id === filterId);
        if (filterObject) {
            setSelectedFilter({ id: filterId, name: filterObject.name });
            console.log(selectedFilter)
            setSelectedCategory(null);
            setCategoriesForSelectedFilter(filterId);
        } else {
            setSelectedFilter({ id: '', name: '' });
            setSelectedCategory(null);
            setCategories([]);
        }
    };

    const onSelectCategory = (categoryId) => {
        setSelectedCategory(categoryId);
        const category = categories.find((cat) => cat.id === categoryId);
        if (category) {
            const products = category.products.map((product) => ({
                ...product,
                price: parseFloat(product.price10x),
            }));
            setSelectedProducts(products || []);
        } else {
            setSelectedProducts([]);
        }
    };

    const addToCart = (product) => {
        const existingItemIndex = cart.findIndex((item) => item.product.id === product.id);
        console.log(product.valor_maximo)
        if (existingItemIndex !== -1) {
            const updatedCart = [...cart];
            updatedCart[existingItemIndex].quantity++;
            setCart(updatedCart);
            updateTotal(product.price10x, 1);
        } else {
            const newCart = [...cart, { id: cart.length + 1, product, quantity: 1 }];
            setCart(newCart);
            updateTotal(product.price10x, 1);
        }
        setSelectedCategory(null);
    };

    const updateTotal = (priceChange, quantityChange) => {
        const newTotal = total + priceChange * quantityChange;
        setTotal(newTotal);
    };

    const handlePasswordSubmit = () => {
        if (password === (POS_PASSWORD || "1247")) {
            setAuthorized(true);
            setOpenDialog(false);
        } else {
            setSnackbarMessage('Senha incorreta');
            setSnackbarOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    const cartRef = useRef(null);

    const handleTakeScreenshot = () => {
        if (elevation === 3) {
            setElevation(0);
        }

        if (elevation === 0) {
            const targetElement = document.getElementById('screenshot-print-target');

            const previousStyle = targetElement.style.boxShadow;

            targetElement.style.boxShadow = 'none !important';

            html2canvas(targetElement)
                .then(canvas => {
                    const imageUrl = canvas.toDataURL();

                    const currentDate = new Date();
                    const fileName = `screenshot_${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}_${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}.png`;

                    const link = document.createElement('a');
                    link.href = imageUrl;
                    link.download = fileName;

                    link.click()
                    // setElevation(3)

                    targetElement.style.boxShadow = previousStyle;

                })
                .catch(error => {
                    console.error('Erro ao capturar a tela:', error);

                    targetElement.style.boxShadow = previousStyle;
                });
        }
    };

    const printCart = () => {
        const targetElement = document.getElementById('screenshot-print-target');
        if (targetElement) {
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                const content = targetElement.innerHTML;
                printWindow.document.write(content);
                printWindow.document.write(`
                    <style>
                        body { margin: 0 auto; max-width: 100%; font-size: 10px; }
                        button { display: none; }
                        table { border-collapse: separate; }
                        td, th { padding-left: 1.5rem; padding-right: 1.5rem; position: relative; }
                        td::after, th::after { content: ''; position: absolute; left: 0; bottom: -1px; width: 100%; border-bottom: 1px solid black; }
                    </style>
                `);
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
            } else {
                console.error('Não foi possível abrir a nova janela.');
            }
        } else {
            console.error('Elemento alvo não encontrado.');
        }
    }

    return (
        <div style={{ paddingTop: '2rem', minHeight: '100vh', position: 'relative' }}>
            <div style={{
                display: 'flex',
                flexDirection: orientation === 'portrait' ? 'column' : 'row',
                alignItems: 'center',
                marginBottom: '1rem',
                justifyContent: 'start',
                gap: orientation === 'portrait' ? '1rem' : '3rem',
                paddingLeft: '3rem',
            }}>
                <img
                    src={nmaispeloImage}
                    alt="Imagem antes dos filtros"
                    style={{
                        width: orientation === 'portrait' ? '50%' : '8rem',
                        marginBottom: orientation === 'portrait' ? '1rem' : '0',
                        transition: 'width 0.5s ease-in-out',
                    }}
                />
                <FilterComponent filters={filters} selectedFilter={selectedFilter} onSelectFilter={onSelectFilter} />
                <div style={{ position: 'absolute', top: '1rem', right: '1.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <UserPopup user={username} password={password} token={token} />
                    <Button onClick={handleTakeScreenshot} style={{ color: 'black' }}> <CameraAltIcon /> </Button>
                    <Button onClick={printCart} style={{ color: "black" }}> <PrintIcon /> </Button>

                </div>
            </div>

            <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={orientation === 'portrait' ? 12 : 6}>
                    <Paper elevation={3} style={{ padding: '37px 16px 0 16px', height: '100%' }}>
                        {filters.length === 0 && (
                            <div style={{ textAlign: 'center' }}>
                                <img src={errorImage} alt="Descrição da Imagem" style={{ width: '400px', height: '350px' }} />
                                <Alert severity='error' style={{ margin: '0 auto' }}>O Sistema parece não estar funcionando corretamente. Contate o administrador do sistema!</Alert>
                            </div>
                        )}

                        {selectedCategory ? (
                            <>
                                <div style={{ position: 'relative', height: '100%' }}>
                                    <ProductComponent products={selectedProducts} onSelectProduct={addToCart} selectedFilter={selectedFilter} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setSelectedCategory(null)}
                                        style={{
                                            marginBottom: 16,
                                            backgroundColor: '#8fd400',
                                            marginTop: 16,
                                            position: 'absolute',
                                            bottom: 0,
                                            boxShadow: 'none', // Remover qualquer sombra adicional
                                        }}
                                        disableElevation // Desativa a elevação
                                    >
                                        Voltar
                                    </Button>
                                </div>
                            </>
                        ) : selectedFilter === 1 ? (
                            <div>
                                {/* <img src={masculinoImage} alt="Imagem Masculino" style={{ height: '100%' }} /> */}
                                <CategoryComponent categories={categories} onSelectCategory={onSelectCategory} />
                            </div>
                        ) : (
                            <CategoryComponent categories={categories} onSelectCategory={onSelectCategory} />
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={orientation === 'portrait' ? 12 : 6}>
                    <div ref={cartRef} id="screenshot-print-target" style={{ height: '100%' }}>
                        <CartComponent
                            cart={cart}
                            total={total}
                            setCart={setCart}
                            setTotal={setTotal}
                            elevation={elevation}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default POSComponent;
