import React, { useState } from 'react';
import {
    IconButton,
    Popover,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    InputAdornment,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserPopup = ({ user, token }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [username, setUsername] = useState(user);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Estado para controlar a visibilidade da senha

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
        handleClose(); // Fecha o popup quando o diálogo abrir
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleUpdate = async () => {
        try {
            const response = await fetch('https://orcamentodigital.app/api/user/update/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Erro da API:', errorData);
                throw new Error(errorData.detail || 'Erro ao atualizar usuário.');
            }

            const data = await response.json();
            console.log('Usuário atualizado com sucesso:', data);

            toast.success('Senha atualizada com sucesso! Atualize a página e faça login novamente.');

            localStorage.removeItem('token');
            navigate('/naomaispelo');

        } catch (error) {
            toast.error(`Erro: ${error.message}`);
            console.error('Erro:', error);
        } finally {
            handleDialogClose();
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'user-popover' : undefined;

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div>
            <Button onClick={handleClick} size="large">
                <AccountCircleIcon style={{ background: "white", color: 'black', borderRadius: '50%' }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '16px', textAlign: 'center' }}>
                    <Typography variant="h6">Informações do Usuário</Typography>
                    <AccountCircleIcon style={{ background: "white", color: '#8FD400', borderRadius: '50%', width: "70px", height: "70px" }} />
                    <Typography variant="body1" gutterBottom>
                        Usuário: {user}
                    </Typography>
                    <Button variant="contained" style={{ background: 'black' }} onClick={handleDialogOpen}>
                        Editar Usuário/Senha
                    </Button>
                </div>
            </Popover>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Editar Usuário e Senha</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Novo Nome de Usuário"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Nova Senha"
                        type={showPassword ? 'text' : 'password'} // Alterar o tipo conforme o estado
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />} {/* Ícone para alternar a visibilidade */}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpdate} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

            <ToastContainer />
        </div>
    );
};

export default UserPopup;
